

























































































































































.option{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.custom-input-wrap{
  margin-bottom:16px;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
